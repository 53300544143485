html,
body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    background-color: rgb(250, 250, 250);
}

.container {
    margin: 80px auto 0 auto;
    max-width: 1080px;
}
